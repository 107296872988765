import { useState, useEffect, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './Progress.css';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const Progress = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [exercises, setExercises] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [personalRecords, setPersonalRecords] = useState([]);
  const [clientSearch, setClientSearch] = useState('');
  const [exerciseSearch, setExerciseSearch] = useState('');
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  const [showExerciseDropdown, setShowExerciseDropdown] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      const { data, error } = await supabase.from('clients').select('*');
      if (!error) setClients(data);
    };
    fetchClients();

    const fetchExercises = async () => {
      const { data, error } = await supabase.from('exercises').select('*');
      if (!error) setExercises(data);
    };
    fetchExercises();
  }, []);

  const filteredClients = clients.filter(client =>
    `${client.first_name} ${client.last_name}`.toLowerCase().includes(clientSearch.toLowerCase())
  );

  const filteredExercises = exercises.filter(exercise =>
    exercise.name.toLowerCase().includes(exerciseSearch.toLowerCase())
  );

  const handleClientSelect = (client) => {
    setSelectedClient(client);
    setClientSearch(`${client.first_name} ${client.last_name}`);
    setShowClientDropdown(false);
  };

  const handleExerciseSelect = (exercise) => {
    setSelectedExercise(exercise);
    setExerciseSearch(exercise.name);
    setShowExerciseDropdown(false);
  };

  const fetchPersonalRecords = useCallback(async () => {
    if (!selectedClient || !selectedExercise) return;

    const { data, error } = await supabase
      .from('personal_records')
      .select('weight, reps, achieved_date')
      .eq('client_id', selectedClient.id)
      .eq('exercise_id', selectedExercise.id)
      .order('achieved_date', { ascending: true });

    if (!error) setPersonalRecords(data);
  }, [selectedClient, selectedExercise]);

  useEffect(() => {
    fetchPersonalRecords();
  }, [fetchPersonalRecords]);

  const chartData = {
    labels: personalRecords.map(pr => new Date(pr.achieved_date).toLocaleDateString()),
    datasets: [
      {
        label: 'Weight (lbs)',
        data: personalRecords.map(pr => pr.weight),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  return (
    <div className="progress-page">
      <h2>Progress</h2>
      <div className="selection">
        <div className="search-container">
          <input
            type="text"
            value={clientSearch}
            onChange={(e) => {
              setClientSearch(e.target.value);
              setShowClientDropdown(true);
            }}
            onFocus={() => setShowClientDropdown(true)}
            placeholder="Search client..."
            className="search-input"
          />
          {showClientDropdown && clientSearch && (
            <div className="dropdown">
              {filteredClients.map(client => (
                <div
                  key={client.id}
                  className="dropdown-item"
                  onClick={() => handleClientSelect(client)}
                >
                  {client.first_name} {client.last_name}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="search-container">
          <input
            type="text"
            value={exerciseSearch}
            onChange={(e) => {
              setExerciseSearch(e.target.value);
              setShowExerciseDropdown(true);
            }}
            onFocus={() => setShowExerciseDropdown(true)}
            placeholder="Search exercise..."
            className="search-input"
          />
          {showExerciseDropdown && exerciseSearch && (
            <div className="dropdown">
              {filteredExercises.map(exercise => (
                <div
                  key={exercise.id}
                  className="dropdown-item"
                  onClick={() => handleExerciseSelect(exercise)}
                >
                  {exercise.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="records">
        <h3>Personal Records</h3>
        {personalRecords.length > 0 ? (
          <>
            <ul>
              {[...personalRecords].reverse().map((pr, index) => (
                <li key={index}>
                  {pr.weight} lbs x {pr.reps} reps on {new Date(pr.achieved_date).toLocaleDateString()}
                </li>
              ))}
            </ul>
            <Line data={chartData} />
          </>
        ) : (
          <p>No records found.</p>
        )}
      </div>
    </div>
  );
};

export default Progress; 