import { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './PreviousWorkouts.css';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const PreviousWorkouts = ({ selectedClient }) => {
  const [recentWorkouts, setRecentWorkouts] = useState([]);

  useEffect(() => {
    const fetchRecentWorkouts = async () => {
      if (!selectedClient) return;

      const { data, error } = await supabase
        .from('workouts')
        .select(`
          *,
          workout_exercises (
            sets,
            reps,
            time,
            weight,
            exercises (
              name,
              muscle_group
            )
          )
        `)
        .eq('client_id', selectedClient.id)
        .eq('completed', true)
        .order('workout_date', { ascending: false })
        .limit(3);

      if (error) {
        console.error('Error fetching workouts:', error);
        return;
      }

      setRecentWorkouts(data);
    };

    fetchRecentWorkouts();
  }, [selectedClient]);

  if (!selectedClient || recentWorkouts.length === 0) {
    return null;
  }

  return (
    <div className="previous-workouts">
      <h3>Previous Workouts for {selectedClient.first_name} {selectedClient.last_name}</h3>
      
      <div className="workouts-container">
        {recentWorkouts.map((workout) => (
          <div key={workout.id} className="workout-card">
            <div className="workout-header">
              <h4>Workout Date: {new Date(workout.workout_date).toLocaleDateString()}</h4>
              <span className="completion-date">
                Completed: {new Date(workout.completed_at).toLocaleDateString()}
              </span>
            </div>

            <div className="exercises-list">
              <div className="exercise-header">
                <span className="exercise-name">Exercise</span>
                <span className="exercise-detail">Sets</span>
                <span className="exercise-detail">Reps</span>
                <span className="exercise-detail">Weight</span>
                <span className="exercise-detail">Time</span>
              </div>
              
              {workout.workout_exercises.map((exercise, index) => (
                <div key={index} className="exercise-row">
                  <span className="exercise-name">
                    {exercise.exercises.name}
                    <span className="muscle-group">({exercise.exercises.muscle_group})</span>
                  </span>
                  <span className="exercise-detail">{exercise.sets || '-'}</span>
                  <span className="exercise-detail">{exercise.reps || '-'}</span>
                  <span className="exercise-detail">{exercise.weight || '-'}</span>
                  <span className="exercise-detail">{exercise.time || '-'}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreviousWorkouts;
