import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import './ClientInfo.css';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const ClientInfo = () => {
  const [formData, setFormData] = useState({
    date: new Date().toISOString().split('T')[0],
    name: '',
    address: '',
    phone: '',
    email: '',
    dateOfBirth: '',
    currentAge: '',
    referralSource: '',
    goals: '',
    previousTrainer: '',
    exerciseFrequency: '',
    strengthTrainingExperience: '',
    injuries: '',
    nutritionHabits: '',
    // PAR-Q Section
    heartCondition: false,
    chestPain: false,
    chestPainNoActivity: false,
    balance: false,
    boneJoint: false,
    bloodPressure: false,
    otherReasons: false,
    // General and Medical History
    occupation: '',
    extendedSitting: '',
    repetitiveMovements: '',
    dressShoes: '',
    mentalDistress: '',
    recreationalActivities: '',
    hobbies: '',
    chronicPain: '',
    surgeries: '',
    chronicDiseases: '',
    medications: '',
    additionalInfo: ''
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isNewClient, setIsNewClient] = useState(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .or(`first_name.ilike.%${searchQuery}%,last_name.ilike.%${searchQuery}%`);

      if (error) throw error;
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching clients:', error);
      alert('Error searching clients: ' + error.message);
    }
  };

  const handleSelectClient = (client) => {
    setSelectedClient(client);
    setIsNewClient(false);
    
    // Format the data to match your form structure
    setFormData({
      // Basic Information
      date: new Date().toISOString().split('T')[0],
      name: `${client.first_name} ${client.last_name}`,
      address: client.address || '',
      phone: client.phone || '',
      email: client.email || '',
      dateOfBirth: client.date_of_birth || '',
      currentAge: client.age?.toString() || '',

      // Initial Questions
      referralSource: client.referral_source || '',
      goals: client.goals || '',
      previousTrainer: client.previous_trainer || '',
      exerciseFrequency: client.exercise_frequency || '',
      strengthTrainingExperience: client.strength_training_experience || '',
      injuries: client.injuries || '',
      nutritionHabits: client.nutrition_habits || '',

      // PAR-Q Section
      heartCondition: client.heart_condition || false,
      chestPain: client.chest_pain || false,
      chestPainNoActivity: client.chest_pain_no_activity || false,
      balance: client.balance || false,
      boneJoint: client.bone_joint || false,
      bloodPressure: client.blood_pressure || false,
      otherReasons: client.other_reasons || false,

      // General and Medical History
      occupation: client.occupation || '',
      extendedSitting: client.extended_sitting || '',
      repetitiveMovements: client.repetitive_movements || '',
      dressShoes: client.dress_shoes || '',
      mentalDistress: client.mental_distress || '',

      // Recreational
      recreationalActivities: client.recreational_activities || '',
      hobbies: client.hobbies || '',

      // Medical
      chronicPain: client.chronic_pain || '',
      surgeries: client.surgeries || '',
      chronicDiseases: client.chronic_diseases || '',
      medications: client.medications || '',
      additionalInfo: client.additional_info || '',


    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Split the name into first and last name
    const nameParts = formData.name.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(' '); // Handles middle names

    const formattedData = {
      // Existing columns
      first_name: firstName,
      last_name: lastName,
      email: formData.email,
      phone: formData.phone,
      age: parseInt(formData.currentAge),
   
      medications: formData.medications,
      notes: formData.additionalInfo,
      
      // New columns in order
      address: formData.address,
      date_of_birth: formData.dateOfBirth,
      referral_source: formData.referralSource,
      goals: formData.goals,
      previous_trainer: formData.previousTrainer,
      exercise_frequency: formData.exerciseFrequency,
      strength_training_experience: formData.strengthTrainingExperience,
      injuries: formData.injuries,
      nutrition_habits: formData.nutritionHabits,
      heart_condition: formData.heartCondition,
      chest_pain: formData.chestPain,
      chest_pain_no_activity: formData.chestPainNoActivity,
      balance: formData.balance,
      bone_joint: formData.boneJoint,
      blood_pressure: formData.bloodPressure,
      other_reasons: formData.otherReasons,
      occupation: formData.occupation,
      extended_sitting: formData.extendedSitting,
      repetitive_movements: formData.repetitiveMovements,
      dress_shoes: formData.dressShoes,
      mental_distress: formData.mentalDistress,
      recreational_activities: formData.recreationalActivities,
      hobbies: formData.hobbies,
      chronic_pain: formData.chronicPain,
      surgeries: formData.surgeries,
      chronic_diseases: formData.chronicDiseases,
      additional_info: formData.additionalInfo
    };

    try {
      let response;
      if (isNewClient) {
        response = await supabase
          .from('clients')
          .insert([formattedData]);
      } else {
        response = await supabase
          .from('clients')
          .update(formattedData)
          .eq('id', selectedClient.id);
      }

      const { error } = response;
      if (error) throw error;

      alert(`Client successfully ${isNewClient ? 'added' : 'updated'}!`);
      // Reset form or redirect
    } catch (error) {
      console.error('Error:', error);
      alert('Error saving client information: ' + error.message);
    }
  };

  const handleReset = () => {
    setFormData({
      date: new Date().toISOString().split('T')[0],
      name: '',
      // ... reset all other fields ...
    });
    setSelectedClient(null);
    setIsNewClient(true);
  };

  return (
    <div className="client-info-container">
      <h1>Client Management</h1>

      {/* Search Section */}
      <section className="form-section search-section">
        <h2>Search Clients</h2>
        <div className="search-container">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by name..."
            className="search-input"
          />
          <button onClick={handleSearch} className="search-btn">Search</button>
          <button onClick={handleReset} className="new-client-btn">New Client</button>
        </div>

        {/* Search Results */}
        {searchResults.length > 0 && (
          <div className="search-results">
            {searchResults.map((client) => (
              <div 
                key={client.id} 
                className="client-result"
                onClick={() => handleSelectClient(client)}
              >
                <span>{client.first_name} {client.last_name}</span>
                <span>{client.email}</span>
              </div>
            ))}
          </div>
        )}
      </section>

      {/* Client Information Form */}
      <form onSubmit={handleSubmit}>
        <h2>{isNewClient ? 'New Client Information' : 'Update Client Information'}</h2>
        
        {/* Basic Information */}
        <section className="form-section">
          <h2>Basic Information</h2>
          
          <div className="form-group">
            <label>Today's Date:</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
          </div>
          
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Phone:</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Date of Birth:</label>
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Current Age:</label>
            <input
              type="number"
              name="currentAge"
              value={formData.currentAge}
              onChange={handleChange}
              required
            />
          </div>
        </section>

        {/* Initial Questions */}
        <section className="form-section">
          <h2>Initial Questions</h2>

          <div className="form-group">
            <label>How did you hear about us?</label>
            <input
              type="text"
              name="referralSource"
              value={formData.referralSource}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>What are your short and long-term goals?</label>
            <textarea
              name="goals"
              value={formData.goals}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Have you ever worked with a personal trainer before? If so, when?</label>
            <input
              type="text"
              name="previousTrainer"
              value={formData.previousTrainer}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>How often do you exercise?</label>
            <input
              type="text"
              name="exerciseFrequency"
              value={formData.exerciseFrequency}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Have you ever participated in strength or weight training exercises?</label>
            <input
              type="text"
              name="strengthTrainingExperience"
              value={formData.strengthTrainingExperience}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Please list any injuries you have:</label>
            <textarea
              name="injuries"
              value={formData.injuries}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Please describe your eating and nutrition habits:</label>
            <textarea
              name="nutritionHabits"
              value={formData.nutritionHabits}
              onChange={handleChange}
            />
          </div>
        </section>

        {/* PAR-Q Section */}
        <section className="form-section">
          <h2>Physical Activity Readiness Questionnaire (PAR-Q)</h2>
          
          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="heartCondition"
                checked={formData.heartCondition}
                onChange={handleChange}
              />
              <span>Has your doctor ever said that you have a heart condition and that you should only perform
              physical activity recommended by a doctor?</span>
            </label>
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="chestPain"
                checked={formData.chestPain}
                onChange={handleChange}
              />
              <span>Do you feel pain in your chest when you perform physical activity?</span>
            </label>
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="chestPainNoActivity"
                checked={formData.chestPainNoActivity}
                onChange={handleChange}
              />
              <span>In the past month, have you had chest pain when you were not performing any physical activity?</span>
            </label>
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="balance"
                checked={formData.balance}
                onChange={handleChange}
              />
              <span>Do you lose your balance because of dizziness or do you ever lose consciousness?</span>
            </label>
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="boneJoint"
                checked={formData.boneJoint}
                onChange={handleChange}
              />
              <span>Do you have a bone or joint problem that could be made worse by a change in your physical activity?</span>
            </label>
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="bloodPressure"
                checked={formData.bloodPressure}
                onChange={handleChange}
              />
              <span>Is your doctor currently prescribing any medication for your blood pressure or for a heart condition?</span>
            </label>
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="otherReasons"
                checked={formData.otherReasons}
                onChange={handleChange}
              />
              <span>Do you know of any other reason why you should not engage in physical activity?</span>
            </label>
          </div>
        </section>

        {/* General and Medical History */}
        <section className="form-section">
          <h2>General and Medical History</h2>

          <div className="form-group">
            <label>What is your current occupation?</label>
            <input
              type="text"
              name="occupation"
              value={formData.occupation}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Does your occupation require extended periods of sitting?</label>
            <input
              type="text"
              name="extendedSitting"
              value={formData.extendedSitting}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Does your occupation require repetitive movements? (If yes, please explain.)</label>
            <textarea
              name="repetitiveMovements"
              value={formData.repetitiveMovements}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Does your occupation require you to wear shoes with a heel (e.g., dress shoes)?</label>
            <input
              type="text"
              name="dressShoes"
              value={formData.dressShoes}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Does your occupation cause you mental distress?</label>
            <input
              type="text"
              name="mentalDistress"
              value={formData.mentalDistress}
              onChange={handleChange}
            />
          </div>
        </section>

        {/* Recreational */}
        <section className="form-section">
          <h2>Recreational</h2>

          <div className="form-group">
            <label>Do you partake in any recreational physical activities (golf, skiing, etc.)?</label>
            <textarea
              name="recreationalActivities"
              value={formData.recreationalActivities}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Do you have any additional hobbies (reading, video games, etc.)?</label>
            <textarea
              name="hobbies"
              value={formData.hobbies}
              onChange={handleChange}
            />
          </div>
        </section>

        {/* Medical */}
        <section className="form-section">
          <h2>Medical</h2>

          <div className="form-group">
            <label>Have you ever had any injuries or chronic pain? (If yes, please explain.)</label>
            <textarea
              name="chronicPain"
              value={formData.chronicPain}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Have you ever had any surgeries? (If yes, please explain.)</label>
            <textarea
              name="surgeries"
              value={formData.surgeries}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Has a medical doctor ever diagnosed you with a chronic disease?</label>
            <textarea
              name="chronicDiseases"
              value={formData.chronicDiseases}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Are you currently taking any medication? (If yes, please explain.)</label>
            <textarea
              name="medications"
              value={formData.medications}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Additional Information:</label>
            <textarea
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
            />
          </div>
        </section>

        <div className="form-actions">
          <button type="submit" className="submit-btn">
            {isNewClient ? 'Save New Client' : 'Update Client'}
          </button>
          {!isNewClient && (
            <button type="button" onClick={handleReset} className="reset-btn">
              Cancel Edit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ClientInfo; 
