import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import Nav from './trainer/Nav';
import Login from './Login';
import Workout from './trainer/workouts/Workout';
import Plans from './trainer/plans/Plans';
import Exercises from './trainer/exercises/Exercises';
import Client from './trainer/client_info/Client';
import Dashboard from './trainer/dashboard/Dashboard';
import Progress from './trainer/progress/Progress';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <Router>
      <div className="App">
        {session && <Nav />}
        <Routes>
          <Route 
            path="/login" 
            element={session ? <Navigate to="/dashboard" /> : <Login />} 
          />
          <Route 
            path="/dashboard" 
            element={session ? <Dashboard /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/exercises" 
            element={session ? <Exercises /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/workouts" 
            element={session ? <Workout /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/plans" 
            element={session ? <Plans /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/client-info" 
            element={session ? <Client /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/progress" 
            element={session ? <Progress /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/" 
            element={session ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
