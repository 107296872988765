import { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './Workout.css';
import PreviousWorkouts from './PreviousWorkouts';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const Workout = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchClient, setSearchClient] = useState('');
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  const [workoutDate, setWorkoutDate] = useState(new Date());
  const [exercises, setExercises] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [workoutRows, setWorkoutRows] = useState([
    { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
    { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
    { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
  ]);
  const [activeRow, setActiveRow] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [user, setUser] = useState(null);
  const [workoutNotes, setWorkoutNotes] = useState('');
  const [showPRContainer, setShowPRContainer] = useState(false);
  const [prExercise, setPrExercise] = useState('');
  const [prWeight, setPrWeight] = useState('');
  const [prReps, setPrReps] = useState('');
  const [prSearchTerm, setPrSearchTerm] = useState('');
  const [filteredPrExercises, setFilteredPrExercises] = useState([]);

  // Fetch clients
  useEffect(() => {
    const fetchClients = async () => {
      const { data, error } = await supabase
        .from('clients')
        .select('*');
      if (!error) {
        setClients(data);
      }
    };
    fetchClients();
  }, []);

  const filteredClients = clients.filter(client => 
    `${client.first_name} ${client.last_name}`.toLowerCase().includes(searchClient.toLowerCase())
  );

  const handleClientSelect = async (client) => {
    setSelectedClient(client);
    setSearchClient(`${client.first_name} ${client.last_name}`);
    setShowClientDropdown(false);

    // Check for incomplete workout
    const { data: incompleteWorkout, error: workoutError } = await supabase
      .from('workouts')
      .select(`
        *,
        workout_exercises (
          id,
          exercise_id,
          sets,
          reps,
          time,
          weight,
          exercises (
            id,
            name,
            muscle_group
          )
        )
      `)
      .eq('client_id', client.id)
      .eq('completed', false)
      .order('workout_date', { ascending: false })
      .limit(1)
      .single();

    if (workoutError && workoutError.code !== 'PGRST116') {
      console.error('Error fetching incomplete workout:', workoutError);
      return;
    }

    if (incompleteWorkout) {
      // Populate form with incomplete workout data
      setWorkoutDate(new Date(incompleteWorkout.workout_date));
      setWorkoutNotes(incompleteWorkout.notes || '');
      
      const exerciseRows = incompleteWorkout.workout_exercises.map(we => ({
        exerciseId: we.exercise_id,
        exerciseName: we.exercises.name,
        muscleGroup: we.exercises.muscle_group,
        sets: we.sets || '',
        reps: we.reps || '',
        time: we.time || '',
        weight: we.weight || ''
      }));

      // If there are fewer exercises than default rows, pad with empty rows
      while (exerciseRows.length < 3) {
        exerciseRows.push({ 
          exerciseId: '', 
          exerciseName: '', 
          muscleGroup: '', 
          sets: '', 
          reps: '', 
          time: '',
          weight: '' 
        });
      }

      setWorkoutRows(exerciseRows);
    } else {
      // Reset form for new workout
      setWorkoutDate(new Date());
      setWorkoutRows([
        { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
        { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
        { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' }
      ]);
    }
  };

  // Fetch exercises from Supabase
  useEffect(() => {
    const fetchExercises = async () => {
      const { data, error } = await supabase
        .from('exercises')
        .select('*');
      if (!error) {
        setExercises(data);
      }
    };
    fetchExercises();
  }, []);

  // Filter exercises based on search term
  useEffect(() => {
    if (searchTerm) {
      const filtered = exercises.filter(exercise =>
        exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredExercises(filtered);
    } else {
      setFilteredExercises([]);
    }
  }, [searchTerm, exercises]);

  // Filter PR exercises based on search term
  useEffect(() => {
    if (prSearchTerm) {
      const filtered = exercises.filter(exercise =>
        exercise.name.toLowerCase().includes(prSearchTerm.toLowerCase())
      );
      setFilteredPrExercises(filtered);
    } else {
      setFilteredPrExercises([]);
    }
  }, [prSearchTerm, exercises]);

  const handleExerciseSelect = (exercise, rowIndex) => {
    const newRows = [...workoutRows];
    newRows[rowIndex] = {
      ...newRows[rowIndex],
      exerciseId: exercise.id,
      exerciseName: exercise.name,
      muscleGroup: exercise.muscle_group,
    };
    setWorkoutRows(newRows);
    setActiveRow(null);
  };

  const handleInputChange = (rowIndex, field, value) => {
    const newRows = [...workoutRows];
    newRows[rowIndex] = {
      ...newRows[rowIndex],
      [field]: value,
    };
    setWorkoutRows(newRows);
  };

  // Get current user on component mount
  useEffect(() => {
    const getCurrentUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    getCurrentUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (!selectedClient || !user) {
        alert('Please select a client');
        return;
      }

      const validRows = workoutRows.filter(row => row.exerciseId);
      if (validRows.length === 0) {
        alert('Please add at least one exercise');
        return;
      }

      // Call stored procedure
      const { data: workoutId, error } = await supabase
        .rpc('handle_workout_submission', {
          p_client_id: selectedClient.id,
          p_trainer_id: user.id,
          p_workout_date: workoutDate.toISOString(),
          p_notes: workoutNotes,
          p_workout_exercises: validRows.map(row => ({
            exercise_id: row.exerciseId,
            sets: row.sets,
            reps: row.reps,
            time: row.time,
            weight: row.weight
          }))
        });

      if (error) {
        console.error('Transaction error:', error);
        throw new Error(error.message || 'Failed to save workout');
      }

      if (!workoutId) {
        throw new Error('No workout ID returned from server');
      }

      resetForm();
      alert('Success! Workout has been saved.');

    } catch (error) {
      console.error('Error:', error);
      alert('Error saving workout: ' + error.message);
    }
  };

  const addExerciseRow = () => {
    setWorkoutRows([
      ...workoutRows,
      { exerciseId: '', exerciseName: '', sets: '', reps: '', time: '', weight: '', muscleGroup: '' }
    ]);
  };

  const handleComplete = async () => {
    if (!selectedClient || !user) {
      alert('Please select a client');
      return;
    }

    const validRows = workoutRows.filter(row => row.exerciseId);
    if (validRows.length === 0) {
      alert('Please add at least one exercise');
      return;
    }

    try {
      // Call stored procedure
      const { data: workoutId, error } = await supabase
        .rpc('complete_workout', {
          p_client_id: selectedClient.id,
          p_workout_date: workoutDate.toISOString(),
          p_notes: workoutNotes,
          p_workout_exercises: validRows.map(row => ({
            exercise_id: row.exerciseId,
            sets: row.sets,
            reps: row.reps,
            time: row.time,
            weight: row.weight
          }))
        });

      if (error) {
        console.error('Transaction error:', error);
        throw new Error(error.message || 'Failed to complete workout');
      }

      if (!workoutId) {
        throw new Error('No workout ID returned from server');
      }

      setIsCompleted(true);
      resetForm();
      alert('Workout completed successfully!');

    } catch (error) {
      console.error('Error completing workout:', error);
      alert('Error completing workout: ' + error.message);
    }
  };

  // Add a reset form function if needed
  const resetForm = () => {
    setSelectedClient(null);
    setSearchClient('');
    setWorkoutDate(new Date());
    setWorkoutRows([
      { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
      { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
      { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' }
    ]);
    setIsCompleted(false);
    setWorkoutNotes('');
  };

  // Add this new function to handle saving PRs
  const handleSavePR = async () => {
    if (!selectedClient || !prExercise || !prWeight || !prReps) {
      alert('Please fill in all PR fields');
      return;
    }

    try {
      const { error } = await supabase
        .from('personal_records')
        .insert({
          client_id: selectedClient.id,
          exercise_id: prExercise,
          weight: parseFloat(prWeight),
          reps: parseInt(prReps),
          verified_by: user.id,
          achieved_date: new Date().toISOString()
        });

      if (error) throw error;

      // Clear the PR form
      setPrExercise('');
      setPrWeight('');
      setPrReps('');
      setShowPRContainer(false);
      
      alert('PR saved successfully!');
    } catch (error) {
      console.error('Error saving PR:', error);
      alert('Error saving PR: ' + error.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="workout-form">
        <div className="workout-header">
          <div className="client-search">
            <input
              type="text"
              value={searchClient}
              onChange={(e) => {
                setSearchClient(e.target.value);
                setShowClientDropdown(true);
              }}
              placeholder="Search client..."
              className="client-search-input"
            />
            {showClientDropdown && searchClient && (
              <div className="client-dropdown">
                {filteredClients.map((client) => (
                  <div
                    key={client.id}
                    className="client-option"
                    onClick={() => handleClientSelect(client)}
                  >
                    {client.first_name} {client.last_name} - {client.email}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="date-picker-container">
            <DatePicker
              selected={workoutDate}
              onChange={(date) => setWorkoutDate(date)}
              className="date-picker"
            />
          </div>
        </div>

        {selectedClient && (
          <div className="client-info">
            <h3>Selected Client: {selectedClient.first_name} {selectedClient.last_name}</h3>
            <p>Goals: {selectedClient.goals || 'None'}</p>
            <p>Limitations: {selectedClient.injuries || 'None'}</p>
          </div>
        )}

        <div className="workout-table">
          <div className="workout-row header">
            <div className="col exercise">Exercise Name</div>
            <div className="col sets">Sets</div>
            <div className="col reps">Reps</div>
            <div className="col weight">Weight</div>
            <div className="col time">Time</div>
            <div className="col muscle-group">Muscle Group</div>
          </div>

          {workoutRows.map((row, index) => (
            <div key={index} className="workout-row">
              <div className="col exercise">
                <input
                  type="text"
                  value={row.exerciseName}
                  onChange={(e) => {
                    handleInputChange(index, 'exerciseName', e.target.value);
                    setSearchTerm(e.target.value);
                    setActiveRow(index);
                  }}
                  onFocus={() => {
                    setActiveRow(index);
                    setSearchTerm(row.exerciseName);
                  }}
                  placeholder="Search exercise..."
                />
                {activeRow === index && searchTerm && (
                  <div className="exercise-dropdown">
                    {filteredExercises.map((exercise) => (
                      <div
                        key={exercise.id}
                        className="exercise-option"
                        onClick={() => handleExerciseSelect(exercise, index)}
                      >
                        {exercise.name} ({exercise.muscle_group})
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              <div className="col sets">
                <input
                  type="text"
                  value={row.sets}
                  onChange={(e) => handleInputChange(index, 'sets', e.target.value)}
                  placeholder="Sets"
                />
              </div>
              
              <div className="col reps">
                <input
                  type="text"
                  value={row.reps}
                  onChange={(e) => handleInputChange(index, 'reps', e.target.value)}
                  placeholder="Reps"
                />
              </div>
              
              <div className="col weight">
                <input
                  type="text"
                  value={row.weight}
                  onChange={(e) => handleInputChange(index, 'weight', e.target.value)}
                  placeholder="Weight"
                />
              </div>
              
              <div className="col time">
                <input
                  type="text"
                  value={row.time}
                  onChange={(e) => handleInputChange(index, 'time', e.target.value)}
                  placeholder="Time"
                />
              </div>

              <div className="col muscle-group">
                <div className="muscle-group-display">{row.muscleGroup}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="workout-notes">
          <h4>Workout Notes</h4>
          <textarea
            value={workoutNotes}
            onChange={(e) => setWorkoutNotes(e.target.value)}
            placeholder="Enter any additional notes about the workout..."
            rows={4}
            className="notes-textarea"
          />
        </div>

        <div className="form-actions">
          <button 
            type="button" 
            onClick={addExerciseRow} 
            className="add-exercise-btn"
          >
            + Add Exercise
          </button>
          <button 
            type="button"
            onClick={() => setShowPRContainer(!showPRContainer)}
            className="add-pr-btn"
          >
           + Add PR
          </button>
          <button 
            type="button"
            onClick={handleComplete}
            className="complete-btn"
            disabled={isCompleted || !selectedClient}
          >
            {isCompleted ? 'Completed' : 'Mark Complete'}
          </button>
          <button 
            type="submit" 
            className="workout-submit-btn"
            disabled={isCompleted}
          >
            Save Workout
          </button>
        </div>

        {showPRContainer && (
          <div className="pr-container">
            <h4>Personal Record</h4>
            <div className="pr-row">
              <div className="col exercise">
                <input
                  type="text"
                  value={prSearchTerm}
                  onChange={(e) => setPrSearchTerm(e.target.value)}
                  placeholder="Search exercise..."
                  className="pr-exercise-dropdown"
                />
                {prSearchTerm && (
                  <div className="exercise-dropdown">
                    {filteredPrExercises.map((exercise) => (
                      <div
                        key={exercise.id}
                        className="exercise-option"
                        onClick={() => {
                          setPrExercise(exercise.id);
                          setPrSearchTerm(`${exercise.name} (${exercise.muscle_group})`);
                        }}
                      >
                        {exercise.name} ({exercise.muscle_group})
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <input
                type="text"
                value={prWeight}
                onChange={(e) => setPrWeight(e.target.value)}
                placeholder="Weight"
                className="pr-weight-input"
              />
              <input
                type="text"
                value={prReps}
                onChange={(e) => setPrReps(e.target.value)}
                placeholder="Reps"
                className="pr-reps-input"
              />
              <button
                type="button"
                onClick={handleSavePR}
                className="workout-submit-btn"
              >
                Save PR
              </button>
            </div>
          </div>
        )}
      </form>
      
      <PreviousWorkouts selectedClient={selectedClient} />
    </>
  );
};

export default Workout; 