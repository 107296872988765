import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './Exercises.css';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const muscleGroups = [
  'LEGS',
  'BACK',
  'CHEST',
  'SHOULDERS',
  'BICEP',
  'TRICEPS',
  'CORE',
  'CARDIO'
];

const Exercises = () => {
  const [exercises, setExercises] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedMuscleGroup, setSelectedMuscleGroup] = useState('');
  const [newExercise, setNewExercise] = useState({
    name: '',
    muscle_group: ''
  });
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchExercises();
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
  };

  const fetchExercises = async (muscleGroup = '') => {
    let query = supabase
      .from('exercises')
      .select('*')
      .order('name');
    
    if (muscleGroup) {
      query = query.eq('muscle_group', muscleGroup);
    }
    
    const { data, error } = await query;
    
    if (error) {
      console.error('Error fetching exercises:', error);
    } else {
      setExercises(data);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newExercise.name || !newExercise.muscle_group) {
      alert('Please fill in all fields');
      return;
    }

    const { error } = await supabase
      .from('exercises')
      .insert([{
        name: newExercise.name,
        muscle_group: newExercise.muscle_group,
        created_by: user.id
      }]);

    if (error) {
      alert('Error adding exercise: ' + error.message);
    } else {
      setNewExercise({ name: '', muscle_group: '' });
      setShowAddForm(false);
      fetchExercises(selectedMuscleGroup);
      alert('Exercise added successfully!');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this exercise?')) {
      const { error } = await supabase
        .from('exercises')
        .delete()
        .eq('id', id);

      if (error) {
        alert('Error deleting exercise: ' + error.message);
      } else {
        fetchExercises(selectedMuscleGroup);
        alert('Exercise deleted successfully!');
      }
    }
  };

  const handleFilter = (muscleGroup) => {
    setSelectedMuscleGroup(muscleGroup);
    fetchExercises(muscleGroup);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="exercises-container">
      <button 
        className="toggle-form-btn"
        onClick={() => setShowAddForm(!showAddForm)}
      >
        {showAddForm ? 'Hide Form' : 'Add New Exercise'}
      </button>

      {showAddForm && (
        <div className="add-exercise-form">
          <h2>Add New Exercise</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Exercise Name"
                value={newExercise.name}
                onChange={(e) => setNewExercise({ ...newExercise, name: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <select
                value={newExercise.muscle_group}
                onChange={(e) => setNewExercise({ ...newExercise, muscle_group: e.target.value })}
                required
              >
                <option value="">Select Muscle Group</option>
                {muscleGroups.map(group => (
                  <option key={group} value={group}>{group}</option>
                ))}
              </select>
            </div>
            <div className="form-actions">
              <button type="submit" className="add-btn">Add</button>
              <button 
                type="button" 
                className="cancel-btn"
                onClick={() => setShowAddForm(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="exercises-list">
        <div className="exercises-header">
          <h2>Exercise List {selectedMuscleGroup && `- ${selectedMuscleGroup}`}</h2>
          <select
            value={selectedMuscleGroup}
            onChange={(e) => handleFilter(e.target.value)}
            className="filter-select"
          >
            <option value="">All Muscle Groups</option>
            {muscleGroups.map(group => (
              <option key={group} value={group}>{group}</option>
            ))}
          </select>
        </div>

        <div className="exercises-table">
          <div className="table-header">
            <div className="col-name">Exercise Name</div>
            <div className="col-group">Muscle Group</div>
            <div className="col-actions">Actions</div>
          </div>
          {exercises.map((exercise) => (
            <div key={exercise.id} className="table-row">
              <div className="col-name">{exercise.name}</div>
              <div className="col-group">{exercise.muscle_group}</div>
              <div className="col-actions">
                <button 
                  className="delete-button"
                  onClick={() => handleDelete(exercise.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Exercises; 